@import 'src/styles/variables';
@import 'src/styles/mixins';

ol {
  &.breadcrumbs {
    @include clearfix;
    @include list-unstyled;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 $gutter-width-x * 0.5;

    @media (min-width: $container-breakpoint) {
      padding: 0;
    }

    li {
      float: left;
      margin-right: 10px;
      font-size: 1.25em;

      &::after {
        margin-left: 10px;
        content: '>';
      }

      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }
}
